<template>
    <div class="vulnerability">
        <a-card :bordered="false" style="margin-top: 20px; margin-bottom: 20px">
            <div class="mx-25 search">
                <a-col :span="24" class="text-right">
                    <a-input-search
                        :placeholder="$t('Projects.ServicesNameQuery')"
                        class="v-search"
                        v-model="queryParams.searchText"
                        @search="handleTableSearch()"
                    />
                </a-col>
            </div>
            <a-table
                rowKey="uuid"
                class="mt-20"
                :loading="loading"
                :columns="columns"
                :data-source="tableData"
                @change="handleChangeTable"
                :pagination="{
                    pageSize: queryParams.pageSize,
                    current: queryParams.pageNumber,
                    total: queryParams.total || 0,
                    showLessItems: true,
                }"
            >
                <template slot="published" slot-scope="published">
                    {{
                        typeof published !== "undefined" ? formatTimestamp(published, true, $t('Projects.FormatTime', lang)) : "-"
                    }}
                </template>
                <template slot="cwe" slot-scope="cwe">
                    {{ typeof cwe !== "undefined" ? "CWE-" + cwe.cweId : "-" }}
                </template>
                <template slot="severity" slot-scope="severity">
                    <div class="table-avatar-info" v-if="severity">
                        <a-avatar
                            v-if="['CRITICAL', 'MEDIUM', 'HIGH', 'LOW'].indexOf(severity) != -1"
                            :size="24"
                            :src="'images/folib/' + severity.toLowerCase() + '.svg'"
                        />
                        <a-avatar v-else shape="circle" :size="24">{{ severity.slice(0, 1) }}</a-avatar>
                        <div class="avatar-info">
                            <p class="mb-0 text-dark">
                                {{
                                    severity === "CRITICAL"
                                        ? "严重"
                                        : severity === "MEDIUM"
                                            ? "中危"
                                            : severity === "HIGH"
                                                ? "高危"
                                                : severity === "LOW"
                                                    ? "低危"
                                                    : severity === "UNASSIGNED" ? "未指定" : severity
                                }}
                            </p>
                        </div>
                    </div>
                </template>
            </a-table>
        </a-card>
    </div>
</template>
<script>
import {getVulnerabilitiesByComponent} from "@/api/projects";
import {formatTimestamp} from "@/utils/util";

export default {
    name: "ComponentVulnerability",
    computed: {
        i18nColumns() {
            return this.columns.map((column) => {
                if (column.i18nKey) {
                    column.title = this.$t(column.i18nKey)
                }
                return column
            })
        },
        lang() {
            return this.$store.state.language.lang
        },
    },
    data() {
        return {
            queryParams: {
                searchText: '',
                pageSize: 10,
                pageNumber: 1,
                total: 0,
            },
            tableData: [],
            columns: [
                {
                    title: () => this.$t('Projects.Name'),
                    dataIndex: "vulnId",
                    sorter: true,
                    scopedSlots: {customRender: "name"},
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: () => this.$t('Projects.Published'),
                    dataIndex: "published",
                    sorter: true,
                    scopedSlots: {customRender: "published"},
                    sortDirections: ["descend", "ascend"],
                },
                {
                    title: 'CWE',
                    dataIndex: "cwe",
                    scopedSlots: {customRender: "cwe"},
                },
                {
                    title: () => this.$t('Projects.Severity'),
                    dataIndex: "severity",
                    scopedSlots: {customRender: "severity"},
                },
            ],
            loading: false,
        }
    },
    created() {
        this.getVulnerabilities();
    },
    methods: {
        formatTimestamp,
        handleChangeTable(pagination, filters, sorter) {
            if (pagination) {
                this.queryParams.pageNumber = pagination.current
            }
            this.queryParams.sortName = sorter.field
            if (sorter && sorter.order === "descend") {
                this.queryParams.sortOrder = "desc"
            } else if (sorter && sorter.order === "ascend") {
                this.queryParams.sortOrder = "asc"
            } else {
                this.queryParams.sortOrder = ""
            }
            this.getVulnerabilities();
        },
        handleTableSearch() {
            this.getVulnerabilities();
        },
        getVulnerabilities() {
            this.uuid = this.$route.params.id
            this.loading = true
            getVulnerabilitiesByComponent(this.uuid, this.queryParams).then(res => {
                this.queryParams.total = +res.headers["x-total-count"]
                this.tableData = res.data
            }).finally(() => {
                this.loading = false
            })
        },
    }
}
</script>

<style scoped lang="scss">
.mx-25 .ant-row-flex {
    flex-wrap: wrap;
}

.v-search {
    max-width: 200px;
    width: 170px;
    min-width: 150px;
    margin-left: 5px;
    margin-bottom: 8px;
}
</style>